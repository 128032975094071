import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import "./PhoneSkins.css";
import axios from "axios";

const PhoneSkins = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [games, setGames] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("All");

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setGames(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Layout>
      <div className="game-page-container">
        <h4>Games</h4>
        <div className="game-search">
          <input
            type="text"
            placeholder="Search Games"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="game-filter-tabs promo-filter-tabs mt-3">
          <button
            onClick={() => setFilter("All")}
            className={`${filter === "All" && "active"}`}
          >
            ALL
          </button>
          <button
            onClick={() => setFilter("Mobile Games")}
            className={`${filter === "Mobile Games" && "active"}`}
          >
            MOBILE GAMES
          </button>
          <button
            onClick={() => setFilter("PC Games")}
            className={`${filter === "PC Games" && "active"}`}
          >
            PC GAMES
          </button>
          <button
            onClick={() => setFilter("Games Vouchers")}
            className={`${filter === "Games Vouchers" && "active"}`}
          >
            GAMES VOUCHERS
          </button>
          <button
            onClick={() => setFilter("Social Media Services")}
            className={`${filter === "Social Media Services" && "active"}`}
          >
            SOCIAL MEDIA
          </button>
        </div>
        <div className="game-container">
          {games
            ?.filter((item) => {
              if (filter !== "All" && item.category !== filter) {
                return false;
              }
              if (
                searchTerm &&
                !item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
              ) {
                return false;
              }
              return true;
            })
            ?.map((item, index) => {
              return (
                <div className="game-cont">
                  <div
                    key={index}
                    className="game"
                    onClick={() => navigate(`/product/${item?.name}`)}
                  >
                    <img
                      src={`https://exstorecredit.com/${item?.image}`}
                      alt="pro-img"
                    />
                    <div className="m-0 text-center">
                      <span>{item?.category}</span>
                      <h5 className="m-0">{item?.name}</h5>
                    </div>
                    <button className="buy-now">Topup</button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default PhoneSkins;
